import {useState} from 'react';
import {faker} from '@faker-js/faker';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {selectProduct} from 'store/production/endpoints/show-product';
import {
  RequestStoreVariant,
  useStoreVariantMutation,
} from 'store/production/endpoints/store-variant';
import {Product} from 'types/product';
import {now} from 'utils/now';
import {useSeeder} from 'utils/use-seeder';
import {ProductVariant} from 'types/product-variant';
import {useSearchParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import toast from 'store/ui/actions/toast';

const __DEV__ = process.env.NODE_ENV === 'development';

export const useVariantForm = () => {
  const [commit, {isLoading, error}] = useStoreVariantMutation();

  const {id, variant} = useParams();

  const serverError = parseValidationErrors(error);

  const seeder = useSeeder();

  const parent = useSelector(selectProduct({id})).data?.data as Product;
  const [searchParams] = useSearchParams();

  const redirectByVariant = (v: ProductVariant) => {
    searchParams.set(
      'filter[product_variant_category_name]',
      String(v.product_variant_category?.name)
    );
    const newParams = searchParams.toString();
    navigate(
      `/dashboard/products/${v.product_id}/variants/${v.id}/general?${newParams}`
    );
  };

  const hasSelfComposedParent =
    parent?.product_collection?.type === 'COMPONENT';

  const [params, setParams] = useState<TT>({
    depth: __DEV__ ? seeder.number({max: 100, min: 5}).toString() : '',
    height: __DEV__ ? seeder.number({max: 100, min: 5}).toString() : '',
    width: __DEV__ ? seeder.number({max: 100, min: 5}).toString() : '',
    diameter: __DEV__ ? seeder.number({max: 100, min: 5}).toString() : '',
    stock_min: '',
    stock_max: '',
    creation_date: now(),
    finition: __DEV__ ? faker.commerce.productMaterial() : '',
    description: __DEV__ ? seeder.paragraph : '',
    comment: __DEV__ ? seeder.paragraph : '',
    catalogue: false,
    variant_category: undefined,
    is_limited_edition: false,
    self_composed: false,
    name: __DEV__ ? seeder.productName : '',
    limited_edition_count: '',
    hasSelfComposedParent,
    is_transferable: false,
    ecotax: undefined,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const handleSubmit = async () => {
    const result = await commit({
      ...params,
      product_id: id,
    });
    if ('data' in result) {
      redirectByVariant(result.data.data);
    } else {
      const {message} = parseValidationErrors(result.error);
      dispatch(toast('error', message));
    }
  };

  return {
    errorMessage: serverError.message,
    isLoading,
    submit: handleSubmit,
    params,
    setParams,
    validationErrors: serverError.errors,
  };
};

type TT = RequestStoreVariant & {};
