import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  action: () => __(translations.projectsPage.action, 'Action'),
  showImage: () => __(translations.projectsPage.showImage, 'Show Image'),
  actionMultipleButton: () => __(translations.projectsPage.actionMultipleButton, 'Action multiple'),
  addProject: () => __(translations.projectsPage.addProduct, 'Add project'),
  all: () => __(translations.projectsPage.all, 'All'),
  archive: () => __(translations.projectsPage.archive, 'Archive'),
  archiveAlertMessage: () => __(translations.projectsPage.archiveAlertMessage, 'are you sure you want to archive this variant?'),
  deleteAlertMessage: () => __(translations.projectsPage.deleteAlertMessage, 'are you sure you want to delete this variant?'),
  details: () => __(translations.projectsPage.details, 'Details'),
  edit: () => __(translations.projectsPage.edit, 'Edit'),
  exports: () => __(translations.projectsPage.exports, 'Exports'),
  name: () => __(translations.projectsPage.name, 'Name'),
  internalName: () => __(translations.projectsPage.internalName, 'Code name'),
  clearSelection: () => __(translations.projectsPage.clearSelection, 'Clear selection'),
  pageTitle: () => __(translations.projectsPage.pageTitle, 'List of Projects'),
  preview: () => __(translations.projectsPage.preview, 'Preview'),
  projects: () => __(translations.projectsPage.projects, 'Projects'),
  projectsList: () => __(translations.projectsPage.projectsList, 'List of projects'),
  projectsTotal: () => __(translations.projectsPage.projectsTotal, 'Total of projects'),
  project: () => __(translations.projectsPage.project, 'Project'),
  client: () => __(translations.projectsPage.client, 'Client name'),
  status: () => __(translations.projectsPage.status, 'Status'),
  type: () => __(translations.projectsPage.type, 'Type'),
  category: () => __(translations.projectsPage.category, 'Category'),
  startsAt: () => __(translations.projectsPage.startsAt, 'Starts At'),
  endsAt: () => __(translations.projectsPage.endsAt, 'Ends At'),
  online: () => __(translations.projectsPage.online, 'Online'),
  offline: () => __(translations.projectsPage.offline, 'Offline'),
  filterBetweenDates: () => __(translations.projectsPage.filterBetweenDates, 'Filter by start & end dates'),

  // project categories
  hotel: () => __(translations.projectsPage.hotel, 'Hostel'),
  restaurant: () => __(translations.projectsPage.restaurant, 'Restaurant'),
  private: () => __(translations.projectsPage.private, 'Private'),
  store: () => __(translations.projectsPage.store, 'Store'),

  // project types
  archi_residence: () => __(translations.projectsPage.archi_residence, 'Residences'),
  archi_hotel_restaurant: () => __(translations.projectsPage.archi_hotel_restaurant, 'Hotels & restaurants'),
  archi_retail: () => __(translations.projectsPage.archi_retail, 'Retail'),
  scenography: () => __(translations.projectsPage.scenography, 'Scenography'),
  design_collaborations: () => __(translations.projectsPage.design_collaborations, 'Design'),
  petits_projets: () => __(translations.projectsPage.petits_projets, 'Petits projets'),
};
