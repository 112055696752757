import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  general: () => __(translations.editProjectGeneralSection.general, 'General'),
  projectName: () => __(translations.editProjectGeneralSection.projectName, 'Project name'),
  privateProjectName: () => __(translations.editProjectGeneralSection.privateProjectName, 'Private project name'),
  customer: () => __(translations.editProjectGeneralSection.customer, 'Customer'),
  clientNotice: () => __(translations.editProjectGeneralSection.clientNotice, 'Client notice'),
  description: () => __(translations.editProjectGeneralSection.description, 'Description'),
  designation: () => __(translations.editProjectGeneralSection.designation, 'Designation'),
  projectStatus: () => __(translations.editProjectGeneralSection.projectStatus, 'Status'),
  projectType: () => __(translations.editProjectGeneralSection.projectType, 'Type'),
  exposedOnWebsite: () => __(translations.editProjectGeneralSection.exposedOnWebsite, 'Exposed on Website'),
  startsAt: () => __(translations.editProjectGeneralSection.startAt, 'Start at'),
  endsAt: () => __(translations.editProjectGeneralSection.endAt, 'End at'),

  informations: () => __(translations.editProjectGeneralSection.informations, 'Informations'),
  settings: () => __(translations.editProjectGeneralSection.settings, 'Settings'),

  // project types
  archi_residence: () => __(translations.editProjectGeneralSection.archi_residence, 'Residences'),
  archi_hotel_restaurant: () => __(translations.editProjectGeneralSection.archi_hotel_restaurant, 'Hotels & restaurants'),
  archi_retail: () => __(translations.editProjectGeneralSection.archi_retail, 'Retail'),
  scenography: () => __(translations.editProjectGeneralSection.scenography, 'Scenography'),
  design_collaborations: () => __(translations.editProjectGeneralSection.design_collaborations, 'Design'),
  petits_projets: () => __(translations.editProjectGeneralSection.petits_projets, 'Petits projets'),
};
