import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router';
import {useSearchParams} from 'react-router-dom';
import {parseValidationErrors} from 'services/api/utils/parse-error';
import {useToOldCollectionVariantActionMutation} from 'store/production/endpoints/action-variant-to-old-collection';
import toast from 'store/ui/actions/toast';
import {selectActiveModalParams} from 'store/ui/selectors';
import {ProductVariant} from 'types/product-variant';

const __DEV__ = process.env.NODE_ENV === 'development';

export default (onDismiss: () => void) => {
  const [submit, {isLoading, error}] =
    useToOldCollectionVariantActionMutation();

  const serverError = parseValidationErrors(error);

  const modalParams = useSelector(selectActiveModalParams);

  const {id, productId, onSuccess, message} = modalParams;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const redirectByVariant = (v: ProductVariant) => {
    searchParams.set(
      'filter[is_old_collection]',
      v.is_old_collection ? '1' : '0'
    );
    navigate(
      `/dashboard/products/${v.product_id}/variants/${
        v.id
      }/general?${searchParams.toString()}`
    );
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const result = await submit({
      id,
    });
    if ('data' in result) {
      dispatch(toast('success', result?.data?.message));
      redirectByVariant(result.data.data);
      onDismiss();
    }
  };

  return {
    message,
    isLoading,
    validationErrors: serverError.errors,
    errorMessage: serverError.message,
    submit: handleSubmit,
  };
};

type CopyParams = {};
