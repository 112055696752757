import React, {ReactNode} from 'react';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import ProductionMonitoring from 'types/production-monitoring';
import FormattedDate from 'components/formatted-date';
import {OrderRelatedPart} from 'features/commercial/orders/types/order-related-part';
import {truncate} from 'lodash';
import RelatedDocument from 'types/related-document';
import {DocumentType} from 'features/commercial/documents/types/commercial-document';
import {ColumnDefCustom} from 'features/table/compenents/table-body';
import {UncontrolledTooltip} from 'reactstrap';

const columns: ColumnDefCustom<ProductionMonitoring>[] = [
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.order" />
      </span>
    ),
    accessorFn: row => (
      <Link target="_BLANK" to={`/dashboard/orders/${row.order_id}/details`}>
        {row.document_ref}
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {
      size: '150px',
    },
    id: 'document_ref',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.announcedDelay" />
      </span>
    ),
    accessorFn: row => (
      <FormattedDate
        value={row.production_declared_completion_date}
        format="date"
      />
    ),
    cell: r => r.getValue(),
    meta: {
      size: '150px',
    },
    id: 'production_declared_completion_date',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.countermark" />
      </span>
    ),
    accessorFn: row => (
      <Link target="_BLANK" to={`/dashboard/directories/${row.directory?.id}`}>
        {row.directory?.display_name}
      </Link>
    ),
    cell: r => r.getValue(),
    meta: {
      size: '150px',
    },
    id: 'directory.display_name',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.refClient" />
      </span>
    ),
    accessorFn: row => row.ref_client,
    cell: r => r.getValue(),
    meta: {
      size: '100px',
    },
    enableHiding: true,
    id: 'ref_client',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.deliveryCountry" />
      </span>
    ),
    accessorFn: row => row.livraison_address_records?.country?.name,
    cell: r => r.getValue(),
    meta: {
      size: '100px',
    },
    enableHiding: true,
    enableSorting: false,
    id: 'livraison_address_records.country.code',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.product" />
      </span>
    ),
    accessorFn: row =>
      row.commercial_products
        ?.map(cc => [cc, ...cc.components])
        .flat()
        .map((c, index) => (
          <HighLighter
            className={`highlight-${c.id}-${c.product_id}`}
            key={`${c.id}-${c.product_id}`}
          >
            <UncontrolledTooltip
              onMouseEnter={() =>
                removeHighlight(`highlight-${c.id}-${c.product_id}`)
              }
              target={`tooltip-${c.id}-${c.product_id}`}
            >
              {c.quantity ? c.name + ' x ' + c.quantity : '' + c.name}{' '}
            </UncontrolledTooltip>
            {c.product_variant_id ? (
              <Link
                id={`tooltip-${c.id}-${c.product_id}`}
                target="_BLANK"
                to={`/dashboard/products/${c.product_id}/variants/${c.product_variant_id}/general`}
                className={`mb-2 d-block highlight-${c.id}-${c.product_id}`}
                style={{paddingLeft: '11px'}}
              >
                {truncate(c.name, {length: 38}) + ' x ' + c.quantity}{' '}
                <span className="opacity-0">/</span>
              </Link>
            ) : (
              <div
                id={`tooltip-${c.id}-${c.product_id}`}
                className={`mb-2 highlight-${c.id}-${c.product_id}`}
                style={{paddingLeft: '11px'}}
              >
                {c.quantity
                  ? truncate(c.name, {length: 38}) + ' x ' + c.quantity
                  : '' + truncate(c.name, {length: 38})}{' '}
                <span className="opacity-0">/</span>
              </div>
            )}
          </HighLighter>
        )),

    cell: r => r.getValue(),
    meta: {
      size: '400px',
      cellCostumClass: 'p-0 pt-3 pb-3',
    },
    enableSorting: false,
    alignMiddle: false,
    id: 'product',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.stockLocation" />
      </span>
    ),
    accessorFn: row =>
      row.commercial_products
        ?.map(cc => [cc, ...cc.components])
        .flat()
        .map((c, index) => {
          return (
            <StockLocationCol
              className={`${c.id}-${c.product_id}`}
              key={`${c.name}-stock_location-${index}`}
              relatedDocument={c.related_documents?.find(
                el => el.document_type === 'SUPPLIER'
              )}
            />
          );
        }),

    cell: r => r.getValue(),
    meta: {
      size: '300px',
      cellCostumClass: 'align-start p-0 pt-3 pb-3',
    },
    enableSorting: false,
    alignMiddle: false,
    id: 'stock_location',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.cf" />
      </span>
    ),
    accessorFn: row =>
      row.commercial_products
        ?.map(cc => [cc, ...cc.components])
        .flat()
        .map((c, index) => {
          return (
            <DocumentRef
              className={`highlight-${c.id}-${c.product_id}`}
              key={`${c.name}-related_documents-${index}`}
              relatedDocument={getRelatedDocumentsByType(
                c.related_documents,
                'SUPPLIER'
              )}
            />
          );
        }),

    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start p-0 pt-3 pb-3',
    },
    enableSorting: false,
    alignMiddle: false,
    id: 'suplier_order',
  },

  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.supplier" />
      </span>
    ),
    accessorFn: row =>
      row.commercial_products
        ?.map(cc => [cc, ...cc.components])
        .flat()
        .map((c, index) => {
          return (
            <SupplierCol
              key={`${c.name}-supplier-${index}`}
              className={`${c.id}-${c.product_id}`}
              relatedDocument={getRelatedDocumentsByType(
                c.related_documents,
                'SUPPLIER'
              )}
            />
          );
        }),

    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start p-0 pt-3 pb-3',
    },
    enableSorting: false,
    alignMiddle: false,
    id: 'suplier',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.supplierDelay" />
      </span>
    ),
    accessorFn: row =>
      row.commercial_products
        ?.map(cc => [cc, ...cc.components])
        .flat()
        .map((c, index) => {
          return (
            <HighLighter
              className={`highlight-${c.id}-${c.product_id}`}
              key={`${c.id}-${c.product_id}`}
            >
              <div
                className={`mb-2 d-block highlight-${c.id}-${c.product_id}`}
                key={`${c.name}-estimated_supplier_time-${index}`}
                style={{paddingLeft: '11px'}}
              >
                <FormattedDate
                  value={
                    getRelatedDocumentsByType(c.related_documents, 'SUPPLIER')
                      ?.estimated_supplier_time
                  }
                  format="date"
                />
                <span className="opacity-0">/</span>
              </div>
            </HighLighter>
          );
        }),

    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start p-0 pt-3 pb-3',
    },
    alignMiddle: false,
    enableSorting: false,
    id: 'estimated_supplier_time',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.clientPayment" />
      </span>
    ),
    accessorFn: row => row.client_payment,
    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start',
    },
    id: 'client_payment',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.transporter" />
      </span>
    ),
    accessorFn: row => row.transporter_name,
    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start',
    },
    enableSorting: false,
    id: 'transporter_name',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.seller" />
      </span>
    ),
    accessorFn: row => row.user_name,
    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start',
    },
    id: 'user_name',
  },
  {
    header: ({table}) => (
      <span className="font-size-14 ">
        <Trans i18nKey="monitoringListingPage.mobilierCMList.comment" />
      </span>
    ),
    accessorFn: row => row.private_comment,
    cell: r => r.getValue(),
    meta: {
      size: '200px',
      cellCostumClass: 'align-start',
    },
    enableSorting: false,
    id: 'comment',
  },
];

export default columns;

const getRelatedDocumentsByType = (
  array: RelatedDocument[],
  document_type: DocumentType
): OrderRelatedPart | undefined => {
  return array?.find(
    el => el.document_type === document_type
  ) as OrderRelatedPart;
};

const DocumentRef = ({
  relatedDocument,
  className,
}: {
  relatedDocument?: OrderRelatedPart;
  className: string;
}) => {
  return (
    <HighLighter className={className}>
      <Link
        target="_BLANK"
        to={`/dashboard/orders/${relatedDocument?.model_id}/details`}
        className={`mb-2 d-block ${className}`}
        style={{paddingLeft: '11px'}}
      >
        {truncate(relatedDocument?.model_ref, {length: 20})}
        <span className="opacity-0">/</span>
      </Link>
    </HighLighter>
  );
};
const SupplierCol = ({
  relatedDocument,
  className,
}: {
  relatedDocument?: OrderRelatedPart;
  className: string;
}) => {
  return (
    <HighLighter className={`highlight-${className}`}>
      {relatedDocument?.supplier_name && (
        <UncontrolledTooltip
          onMouseEnter={() => removeHighlight(`highlight-${className}`)}
          target={`tooltip-supplier-${className}`}
        >
          {relatedDocument?.supplier_name}
        </UncontrolledTooltip>
      )}
      <Link
        id={`tooltip-supplier-${className}`}
        target="_BLANK"
        to={`/dashboard/directories/${relatedDocument?.supplier_id}`}
        className={`mb-2 d-block highlight-${className}`}
        style={{paddingLeft: '11px'}}
      >
        {truncate(relatedDocument?.supplier_name, {length: 21})}
        <span className="opacity-0">/</span>
      </Link>
    </HighLighter>
  );
};
const StockLocationCol = ({
  relatedDocument,
  className,
}: {
  relatedDocument?: OrderRelatedPart;
  className: string;
}) => {
  const location_name = relatedDocument?.stock_location
    ?.map(sl => sl.stock_location_name_quantity)
    ?.join(', ');

  return (
    <HighLighter className={`highlight-${className}`}>
      {location_name && (
        <UncontrolledTooltip
          onMouseEnter={() => removeHighlight(`highlight-${className}`)}
          target={`tooltip-location-${className}`}
        >
          {location_name}
        </UncontrolledTooltip>
      )}
      <p
        id={`tooltip-location-${className}`}
        className={`mb-2 highlight-${className}`}
        style={{paddingLeft: '11px'}}
      >
        {truncate(location_name, {length: 30})}
        <span className="opacity-0">/</span>
      </p>
    </HighLighter>
  );
};

const HighLighter = ({
  children,
  className,
}: {
  children: ReactNode;
  className: string;
}) => {
  return (
    <span
      onMouseEnter={() => addHighlight(className)}
      onMouseLeave={() => removeHighlight(className)}
    >
      {children}
    </span>
  );
};

const addHighlight = (className: string) => {
  const elements = document.querySelectorAll(`.${className}`);

  elements?.forEach(element => {
    element.classList.add('bg-light');
  });
};

const removeHighlight = (className: string) => {
  const elements = document.querySelectorAll(`.${className}`);

  elements?.forEach(element => {
    element.classList.remove('bg-light');
  });
};
