import {api} from 'services/api';
import {ObjectId} from 'types/object-id';
import {Option} from 'types/option';
import {ProductVariant} from 'types/product-variant';
import {ResponseData} from 'types/response-data';

const storeVariant = api.injectEndpoints({
  endpoints: builder => ({
    storeVariant: builder.mutation<Response, RequestStoreVariant>({
      query: params => {
        return {
          method: 'POST',
          url: `products/${params.product_id}/variants`,
          body: {
            catalog: params.catalogue,
            color_id: params.color?.id,
            comment: params.comment,
            creation_date: params.creation_date,
            depth: params.depth,
            description: params.description,
            finition: params.finition,
            height: params.height,
            is_limited_edition: params.is_limited_edition,
            limited_edition_count: params.limited_edition_count,
            matter_id: params.matter?.id,
            width: params.width,
            diameter: params.diameter,
            product_variant_category_id: params.variant_category?.id,
            self_composed: params.self_composed || params.hasSelfComposedParent,
            name: params.name,
            is_transferable: params.is_transferable,
            ecotax_id: params.ecotax?.id,
          },
        };
      },
      // @ts-expect-error
      invalidatesTags: (_result, _error) => {
        if (_result) {
          return [{type: 'variants'}];
        }
        return [];
      },
    }),
  }),
});

export type RequestStoreVariant = {
  color?: Option;
  width?: string | number;
  height?: string | number;
  diameter: string | number;
  stock_min?: string;
  stock_max?: string;
  depth?: string | number;
  variant_category?: Option;
  finition?: string;
  catalogue?: boolean;
  id?: ObjectId;
  limited_edition_count?: string | number;
  matter?: Option;
  product_id?: ObjectId;
  creation_date?: string;
  is_limited_edition: boolean;
  comment: string;
  description: string;
  self_composed?: boolean;
  name: string;
  hasSelfComposedParent?: boolean;
  is_transferable?: boolean;
  ecotax?: Option;
};

type Response = ResponseData<ProductVariant>;

export const {useStoreVariantMutation} = storeVariant;

export const storeVariantEndpoint = storeVariant.endpoints.storeVariant;
