import {translations} from 'locales/translations';
import {__} from 'utils/messages';

export default {
  addPackaging: () => __(translations.updateGeneralTabPage.addPackaging, 'Add packaging'),
  catalog: () => __(translations.updateGeneralTabPage.catalog, 'Catalog'),
  category: () => __(translations.updateGeneralTabPage.category, 'Category'),
  color: () => __(translations.updateGeneralTabPage.color, 'Color'),
  comment: () => __(translations.updateGeneralTabPage.comment, 'Comment'),
  description: () => __(translations.updateGeneralTabPage.description, 'Description'),
  dimension: () => __(translations.updateGeneralTabPage.dimension, 'Dimension (cm) (H x L x P x D)'),
  dimensions: () => __(translations.updateGeneralTabPage.dimensions, 'package dimensions'),
  finition: () => __(translations.updateGeneralTabPage.finition, 'Finition'),
  height: () => __(translations.updateGeneralTabPage.height, 'Height (cm)'),
  label: () => __(translations.updateGeneralTabPage.label, 'Label'),
  length: () => __(translations.updateGeneralTabPage.length, 'Length (cm)'),
  limited: () => __(translations.updateGeneralTabPage.limited, 'Limited edition'),
  matter: () => __(translations.updateGeneralTabPage.matter, 'Material'),
  numberOfPieces: () => __(translations.updateGeneralTabPage.numberOfPieces, 'Number of pieces'),
  selfComposed: () => __(translations.updateGeneralTabPage.selfComposed, 'Self composed'),
  creationDate: () => __(translations.updateGeneralTabPage.creationDate, 'Date of creation'),
  stockMin: () => __(translations.updateGeneralTabPage.stockMin, 'Stock min'),
  stockMax: () => __(translations.updateGeneralTabPage.stockMax, 'Stock max'),

  netWeight: () => __(translations.updateGeneralTabPage.netWeight, 'Net weight (kg)'),
  size: () => __(translations.updateGeneralTabPage.size, 'Size'),
  skuCode: () => __(translations.updateGeneralTabPage.skuCode, 'SKU Code'),
  volume: () => __(translations.updateGeneralTabPage.volume, 'Volume (m3)'),
  weight: () => __(translations.updateGeneralTabPage.weight, 'Weight (kg)'),
  width: () => __(translations.updateGeneralTabPage.width, 'Width (cm)'),
  name: () => __(translations.updateGeneralTabPage.name, 'Name'),
  title: () => __(translations.updateGeneralTabPage.title, 'General of'),
  transferableProduct: () => __(translations.updateGeneralTabPage.transferableProduct, 'Transferable product'),
  ecoTax: () => __(translations.updateGeneralTabPage.ecoTax, 'Eco-tax'),
};
